<template>
  <div class="bedList fatherHeight contBox">
    <el-tabs v-model="tagName" class="patent-tabs" @tab-click="handleClick">
      <el-tab-pane label="床位列表" name="bedList">
        <list ref="listRef"></list>
      </el-tab-pane>
      <el-tab-pane label="透析分组" name="dialysis">
        <dialysis ref="dialysisRef"></dialysis>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import list from './list/index.vue'
import dialysis from './dialysis/index.vue'

const tagName = ref('bedList')
const listRef = ref(null)
const dialysisRef = ref(null)
const handleClick = (tab) => {
  if (tab.props.name == 'bedList') {
    listRef.value.initData()
  } else {
    dialysisRef.value.initData()
  }
}
</script>

<style scoped lang="scss">
.bedList {
  &:deep(.el-tabs) {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 48px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
